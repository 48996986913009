import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "react-router-dom";
import queryString from 'query-string';
import MetaTags from 'react-meta-tags';
import { Redirect } from 'react-router';
import moment from "moment";

import * as ui from '../utilities/ui';
import * as content from '../utilities/content';

import Template from '../templates/template';

import BlockBook from '../components/block/block-book';
import BlockBookCategories from '../components/block/block-book-categories';
import BlockBookTags from '../components/block/block-book-tags';
import BlockBookTools from '../components/block/block-book-tools';
import BlockDownloads from '../components/block/block-downloads';
import BlockQuote from '../components/block/block-quote';
import BlocksRecentlyViewed from '../components/blocks/blocks-recently-viewed';
import BlockReviews from '../components/block/block-reviews';
import BlockSpecifications from '../components/block/block-specifications';

const BookTab = inject("stores") (
    observer (
        class BookTab extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    view: this.props.view
                }

                this.downloadsRef = React.createRef();

                this.storeUi = this.props.stores.storeUi;
            }

            componentDidMount = async() => {

            }

            componentDidUpdate = (prevProps) => {
                if (prevProps.gotoDownloads !== this.props.gotoDownloads) {
                    ui.smoothScrollTo(0, this.downloadsRef.offsetTop - 80, 500)
                }
            }

            componentWillUnmount = () => {

            }

            renderView(view, book) {
                switch(view) {
                    case "description":
                    default:
                        return (
                            <div className="segment__content  book__description">
                                {book.description !== '0' &&
                                    <p 
                                        dangerouslySetInnerHTML={{ __html: book.description }}
                                    />
                                }

                                {book.description === '0' &&
                                    <p>Er is geen beschrijving aanwezig</p>
                                }

                                <div
                                    ref={ (ref) => this.downloadsRef=ref}
                                >
                                    <BlockDownloads 
                                        sku = {book.sku}
                                    />
                                </div>
                            </div>
                        )
                    case "reviews":
                        return (
                            <BlockReviews 
                                book = {book}
                                sku = {book.sku}
                            />
                        ) 
                    case "specifications":
                        return (
                            <BlockSpecifications
                                book = {book}
                            />
                        )
                }
            }
              

            render() {
                const book = this.props.book;

                return (
                    this.renderView(this.props.view, book)
                )
            }
        }
    )
)

const Li = inject("stores") (
    observer (
        class Li extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    active_view: this.props.active_view
                }

                this.storeUi = this.props.stores.storeUi;
            }

            componentDidUpdate = (prevProps) => {
                if (prevProps.active_view !== this.props.active_view) {
                    this.setState({
                        active_view: this.props.active_view
                    })
                }
            }
        
            render() {
                const activeClass = ((this.props.view === this.state.active_view) || (this.props.view == 'description' && this.state.active_view == undefined)) ? ' --active' : '';

                return (
                    <li className={"content-tabs__listitem content-tabs__listitem--" + this.props.view + activeClass} onClick={(e) => this.props.handleContentTab(e, this.props.view)}>
                        {this.props.view === 'reviews' && this.props.nr === 0
                            ?   <span>{i18n.t("tabs.no_reviews")}</span>
                            :   <React.Fragment>
                                    <span>{i18n.t("tabs." + this.props.view)}</span> 
                                    {this.props.view === 'reviews' && (
                                        <span className="content-tabs__listitem--count">({this.props.nr})</span>
                                    )}
                                </React.Fragment>
                        }
                    </li>
                )
            }
        }
    )
)

const Book = inject("stores") (
    observer (
        class Book extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPP = process.env.REACT_APP_URL;
                this.state = {
                    view: 'description',
                    scroll: true,
                    redirect: false,
                    redirectSku: '',
                    searching: false,
                    gotoDownloads: false
                }

                this.blockRef = React.createRef();
                this.formRef = React.createRef();

                this.storeAuth = this.props.stores.storeAuth;
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeOrder = this.props.stores.storeOrder;
                this.storeHistory = this.props.stores.storeHistory;
            }

            componentDidMount = async() => {
                const quote = this.storeUi.getQuote();

                this.setState({
                    searching: true,
                    quote: quote
                })

                const shop = await this.storeUi.checkShopForSku(this.props.match.params.sku);
                  
                const redirectSku = await this.checkRedirectISBN(this.props.match.params.sku);
                if (redirectSku !== null && redirectSku !== '') {                  
                    this.redirectISBN(redirectSku);
                } else {
                    this.setAffiliate(this.props.location.search);
                    this.storeUi.setCurrentBook(this.props.match.params.sku);
                }

                if (this.storeBooks.bookList_top60.length == 0) {
                    (async() => await this.storeBooks.getBooksTop60() )();
                }

                const book = await this.storeBooks.getBookBySku(this.props.match.params.sku, shop);

                if (shop === 'SHOP_ALL') {
                    this.storeGeneral.setShop("SHOP_ALL");
                    this.storeGeneral.setActiveShop("SHOP_ALL");
                    this.storeUi.setActiveToggleNav(3);
                } else {
                    if (book.sb_edition === 'eigen uitgaven') {
                        this.storeGeneral.setShop("SHOP_SB");
                        this.storeGeneral.setActiveShop("SHOP_SB");
                        this.storeUi.setActiveToggleNav(1);
                    } else {
                        this.storeGeneral.setShop("SHOP_SB");
                        this.storeGeneral.setActiveShop("SHOP_SB");
                        this.storeUi.setActiveToggleNav(2);
                    }
                }

                let reviews = await this.storeBooks.getReviews(this.props.match.params.sku);

                if ( this.props.match.params.tab !== undefined) {
                    this.setState({
                        book: book,
                        view: 'description'
                    }, () => this.setCategory());
                } else {
                    this.setState({
                        book: book,
                        view: "description",
                        searching: false
                    }, () => this.setCategory());
                }

                //SbC for SEO
                //SbC TODO add query string
                const id = content.formatTitle(book && book.title);
                const url = "/book/" + this.props.match.params.sku + "/" + id;
                //const url = "/book/" + this.props.match.params.sku;
                this.props.history.replace(url);
            }

            componentDidUpdate = async(prevProps) => {
                // if (this.props.match.params.tab !== 'description' && this.props.match.params.tab !== 'reviews' && this.props.match.params.tab !== 'specifications') {
                //     //ui.handleScroll('top');
                // }

                if (prevProps.match.params.sku !== this.props.match.params.sku) {
                    const quote = this.storeUi.getQuote();

                    let shop = await this.storeUi.checkShopForSku(this.props.match.params.sku);
                    //this.storeGeneral.setShop(shop);
                    //this.storeGeneral.setActiveShop(shop);

                    const redirectSku = await this.checkRedirectISBN(this.props.match.params.sku); 
                 
                    if (redirectSku !== null && redirectSku !== '') {
                        this.redirectISBN(redirectSku);
                    } else {
                        this.setState({
                            view: 'description',
                            redirect: false,
                            searching: true,
                            quote: quote
                        })
                    }

                    let reviews = await this.storeBooks.getReviews(this.props.match.params.sku);

                    const book = await this.storeBooks.getBookBySku(this.props.match.params.sku, shop);

                    if (shop === 'SHOP_ALL') {
                        // this.storeGeneral.setShop("SHOP_ALL");
                        // this.storeGeneral.setActiveShop("SHOP_ALL");
                        this.storeUi.setActiveToggleNav(3);
                    } else {
                        // this.storeGeneral.setShop("SHOP_SB");
                        // this.storeGeneral.setActiveShop("SHOP_SB");
                        if (book.sb_edition === 'eigen uitgaven') {
                            this.storeUi.setActiveToggleNav(1);
                        } else {
                            this.storeUi.setActiveToggleNav(2);
                        }
                    }

                    this.setState({
                        view: 'description',
                        book: book,
                        searching: false
                    }, () => this.setCategory());

                    const id = (this.storeAuth.user.is_loggedin) ? this.storeAuth.user.user_id : 0;
                    this.storeHistory.addToRecentlyViewed(prevProps.match.params.sku, id);

                    let url =  this.props.match.params.sku + "/" + book.title;
                    this.props.history.push(url);
                    
                    //this.handleScroll();
                }

                // if (prevProps.match.params.tab !== this.props.match.params.tab) { 
                //     this.setState({
                //         view: (this.props.match.params.tab === 'description' || this.props.match.params.tab === 'reviews' || this.props.match.params.tab === 'specifications') ? this.props.match.params.tab : 'description',
                //         searching: false
                //     });
                // }             
            }

            componentWillUnmount = () => {
                const id = (this.storeAuth.user.is_loggedin) ? this.storeAuth.user.user_id : 0;
                this.storeHistory.addToRecentlyViewed(this.props.match.params.sku, id);
            }

            handleScroll = () => {
                window.scrollTo(0, 0);
            }

            setAffiliate(searchString) {
                const params = queryString.parse(searchString)
                
                if (params.pc) {
                    let pc = params.pc.trim();
                    this.storeOrder.setAffiliateId(pc);
                }
            }

            checkRedirectISBN = async(sku) => {
                let redirectSku = '';
                const book = await this.storeBooks.getBookBySku(sku);
                if (book && book.alternative !== null && book.alternative !== undefined) {
                    redirectSku = book.alternative;
                }
                return redirectSku;
            };

            redirectISBN = async(sku) => {
                const redirectedBook = await this.storeBooks.getBookDetailsBySku(sku);

                this.setState({
                    redirect: true,
                    redirectSku: sku
                });
            };

            handleContentTab = (e, view) => {
                ui.smoothScrollTo(0, this.blockRef.offsetTop - 80, 500)
                let tab = "/book/" + this.props.match.params.sku + "/" + view;
                if (view !== 'description' && view !== 'reviews' && view !== 'specifications') {
                    this.props.history.push(tab);
                }
                this.setState({
                    view: view
                })
            }

            // handleOnClickCat = (e, cat_name) => {
            //     this.storeUi.setActiveCategory(cat_name);
            //     let url = "/category/" + cat_name;
            //     this.props.history.push(url);
            // }

            // handleOnClickAuthor = (e, firstname, lastname) => {
            //     const author = this.storeBooks.authorsList
            //         .filter(author => author.firstname == firstname && author.lastname == lastname)

            //     if (author.length > 0) {
            //         const author_id = author[0].id;
            //         this.storeUi.setCurrentAuthor(author_id);
            //         this.storeBooks.getBooksForAuthor(this.storeUi.page_view, author_id);

            //         const url = "/author/" + author_id; 
            //         this.props.history.push(url);
            //     }
            // }

            handleOnClickMore = (e) => {
                //ui.smoothScrollTo(0, this.blockRef.offsetTop - 80, 500);
                this.handleContentTab(e, 'description')
            }

            handleViewTab = (e, tab) => {
                //ui.smoothScrollTo(0, this.blockRef.offsetTop - 80, 500);
                this.handleContentTab(e, tab)
            }

            handleViewDownloads = (e) => {
                //this.handleContentTab(e, tab);

                //ui.smoothScrollTo(0, this.blockRef.offsetTop - 80, 500)
                // let tab = "/book/" + this.props.match.params.sku + "/" + view;
                // if (view !== 'description' && view !== 'reviews' && view !== 'specifications') {
                //     this.props.history.push(tab);
                // }
                const view = 'description';

                this.setState({
                    view: view,
                    gotoDownloads: true
                })
            }

            setCategory = async() => {
                let idx;
                let categories = [];

                if (this.storeGeneral.shop === 'SHOP_SB') {
                    if (this.state.book) {
                        if (this.storeBooks.categoriesListSB.length === 0) {
                            categories = await this.storeBooks.returnCategories(this.storeGeneral.shop);
                        } else {
                            categories = this.storeBooks.categoriesListSB;
                        }
                        idx = categories
                            .findIndex((item) => item.name === this.state.book.category); 
                    }
                   
                    if (idx !== undefined && idx !== -1) {
                        const cat_name = categories[idx].name;
                        this.storeUi.setActiveCategory(cat_name);
                    }
                } else {
                    if (this.state.book) {                       
                        categories = await this.storeBooks.getBookCategories(this.state.book.sku);
                        if (categories) {
                            idx = categories
                                .findIndex((item) => item.is_main_cat === 1); 
                        }
                    }

                    if (idx !== undefined && idx !== -1) {
                        let cat_name = categories[idx].category;
                        this.storeUi.setActiveCategory(cat_name);
                    }
                }
            }
      
            render() {
                if (this.state.redirect) {
                    return <Redirect 
                        push to={"/book/" + this.state.redirectSku} 
                    />;
                }

                //SbC not quite sure if this needed still
                //const book = this.storeBooks.current_book;
                
                let reviewsList = this.storeBooks.reviewsList;
                let nrReviews = 0;
                if (reviewsList && reviewsList.length > 0) {
                    nrReviews = reviewsList.length;
                }
                           
                return (
                    <Template
                        type = 'default'
                        reset = {false}
                        sticky = {true}
                    >   
                        <React.Fragment>
                            {this.state.searching &&
                                <div>
                                    {/* De gegevns van het boek worden opgehaald */}
                                </div>
                            }

                            {!this.state.searching && !this.state.book &&
                                <div>
                                    Dit boek is nog niet in onze database opgenomen
                                </div>
                            }
                            
                            {this.state.book &&
                                <MetaTags>
                                    <title 
                                        dangerouslySetInnerHTML={{ __html: this.state.book.title}} 
                                    />
                                    <meta 
                                        name="title" 
                                        content={this.state.book.title} 
                                    />
                                    <meta 
                                        name="description" 
                                        content={this.state.book.short_description} 
                                    />
                                    <meta 
                                        property="og:title" 
                                        content={this.state.book.title + ' | ' + this.state.book.sku} 
                                    />
                                    <meta 
                                        property="og:description" 
                                        content={this.state.book.title + ' | ' + this.state.book.sku} 
                                    />
                                    <meta 
                                        property="og:image" 
                                        content="https://www.succesboeken.nl/api/public/images/branding/sb_logo-img.png" 
                                    />
                                    <link 
                                        rel="canonical" 
                                        href={this.urlAPP + "/book/" + this.state.book.sku + '/' + this.state.book.title} 
                                    />
                                </MetaTags>
                            }

                            {this.storeGeneral.shop !== 'SHOP_ALL' &&
                                <BlockQuote 
                                    quote = {this.state.quote}
                                />
                            }

                            {this.state.book &&
                                <div className="book-wrapper book">
                                    <div className="block--book">
                                        <BlockBook 
                                            type = "productpage"
                                            book = {this.state.book}
                                            sku = {this.state.book.sku}
                                            hasAlternative = {this.state.book.alternative !== null ? true : false}
                                            onClickMore = {this.handleOnClickMore}
                                            viewTab = {this.handleViewTab}
                                        />
                                    </div>
                                    
                                    {this.state.book.alternative === null &&
                                        <BlockBookTools 
                                            book = {this.state.book}
                                            viewDownloads = {this.handleViewDownloads}
                                        />
                                    }

                                    <div className="content-wrapper__segment">
                                        {(this.state.book.sku) &&
                                            <React.Fragment>
                                                <BlockBookCategories 
                                                    sku = {this.state.book.sku}
                                                />
                                                <BlockBookTags 
                                                    sku = {this.state.book.sku}
                                                />
                                            </React.Fragment>
                                        }
                                    </div>

                                    <div className="content-wrapper__segment" ref={ (ref) => this.blockRef=ref }>
                                        <div className="segment__content">
                                            <div className="book__tabs">
                                                <ul className="content-tabs__list">
                                                    <Li 
                                                        view = 'description'
                                                        active_view = {this.state.view}
                                                        handleContentTab = {(e) => this.handleContentTab(e, 'description')}
                                                    />
                                                    <Li 
                                                        view = 'reviews'
                                                        active_view = {this.state.view}
                                                        nr = {nrReviews}
                                                        handleContentTab = {(e) => this.handleContentTab(e, 'reviews')}
                                                    />
                                                    <Li 
                                                        view = 'specifications'
                                                        active_view = {this.state.view}
                                                        handleContentTab = {(e) => this.handleContentTab(e, 'specifications')}
                                                    />
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="content-wrapper__segment">
                                        <BookTab
                                            view = {this.state.view}
                                            book = {this.state.book}
                                            gotoDownloads = {this.state.gotoDownloads}
                                        />
                                    </div>  

                                    <div className="content-wrapper__segment">
                                        <BlocksRecentlyViewed />
                                    </div> 
                                </div>
                            }
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withRouter(Book);
