import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as authors from '../communicator/authors';

class StoreCatalogs {

    activeCatalog = ''
    activeCategory = ''
    activeSubCategory = ''
    activeCount = 0
    stockPriority = 1
    listAuthors = []

    constructor() {
        this.getAuthorsAll();
    }

    async getAuthorsAll() {
        try {
            const result = await authors.getAuthorsAll();
            runInAction(() => {
                this.listAuthors = result.data;
                console.log('SbC authors', result.data)
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }
    }

    getActiveCatalog() {
        return this.activeCatalog;
    }

    setActiveCatalog(value){
        this.activeCatalog = value;
    }

    getActiveCategory() {
        return this.activeCategory;
    }

    setActiveCategory(value){
        this.activeCategory = value;
    }

    getActiveSubCategory() {
        return this.activeSubCategory;
    }

    setActiveSubCategory(value){
        this.activeSubCategory = value;
    }

    getActiveCount() {
        return this.activeCount;
    }

    setActiveCount(value){
        this.activeCount = value;
    }

    setStockPriority(value){
        this.stockPriority = value;
    }

    getStockPriority(value){
        return this.stockPriority;
    }

    async getBreadcrumbs() {
        const activeCatalog = this.getActiveCatalog();
        const activeCategory = this.getActiveCategory();
        const activeSubCategory = this.getActiveSubCategory();
        const activeCount = this.getActiveCount();

        let breadcrumbs = {
            catalog: activeCatalog,
            category: activeCategory,
            subcategory: activeSubCategory,
            count: activeCount
        }

        return breadcrumbs;
    }
}

decorate(StoreCatalogs, {
    activeCatalog: observable,
    activeCategory: observable,
    activeSubCategory: observable,
    activeCount: observable,
    stockPriority: observable,
    getBreadcrumbs: action
})

export default StoreCatalogs;