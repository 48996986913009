import * as apifetch from './fetch.js';

export async function getQuote(id) {
    let urlEndpoint;
    if (id) {
        urlEndpoint = '/content/quote/' + id;
    } else {
        urlEndpoint = '/content/quote'
    }

    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function getBanner() {
    const urlEndpoint = '/content/banner';

    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function getPromoBooks() {
    const urlEndpoint = '/content/promobooks';

    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function getVideos(view) {
    const urlEndpoint = '/content/videos';

    if (view == 'overview') {
        const postData = {
            view: view
        };
        return apifetch.authFetch(urlEndpoint, 'POST', postData);
    } else {
        return apifetch.authFetch(urlEndpoint, 'GET');
    }
}

export async function getVideoById(id) {
    const urlEndpoint = '/content/video/' + id;

    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function getVideoByReference(reference) {
    const urlEndpoint = '/content/video/' + reference;

    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function getVideoLabels(view) {
    const urlEndpoint = '/content/videos/' + view;

    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function getVideosByLabel(view, label) {
    const urlEndpoint = '/content/videos/' + view + '/' + label;

    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function getNewsletters() {
    const urlEndpoint = '/content/newsletters';

    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function getNewsletterTips(newsletter_id) {
    const urlEndpoint = '/content/newsletters/' + newsletter_id;

    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function getTipsCarouselHome() {
    const urlEndpoint = '/v2/catalog/tipshome';

    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function getNotifications() {
    const urlEndpoint = '/content/notifications';

    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function getContentText(page) {
    const urlEndpoint = '/content/text/' + page;

    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function getTooltip(sku) {
    const urlEndpoint = '/content/tooltip/' + sku;

    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function getContentBlocks(block) {
    const urlEndpoint = '/content/blocks/' + block;

    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function copyCover(sku) {
    const urlEndpoint = '/content/copyCover.php';

    const postData = {
        sku: sku
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function uploadFile(file) {
    const urlEndpoint = '/content/uploadFile.php';

    const postData = {
        file: file
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getShortUrl(sku, params) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlAPP = process.env.REACT_APP_URL;
    const API_KEY_GOTAG = process.env.REACT_APP_KEY_GOTAG;
    const urlShortener = 'https://ogtag.me/';
    const urlEndpoint = urlShortener + API_KEY_GOTAG;

    let url;
    let image;

    if (params.type == 'book') {
        image = urlAPI + "/public/images/CoversVK/" + sku + ".jpg";
        if (params.pc !== 0) {
            url = urlAPP + "/book/" + sku + "?pc=" + params.pc;        
        } else {
            url = urlAPP + "/book/" + sku;
        }
    }

    if (params.type == 'video') {
        image = urlAPI + "/public/images/sharing/sb_logo-img-small.png";
        url = urlAPP + "/videos";
    }

    if (params.type == 'newsletter') {
        image = urlAPI + "/public/images/sharing/sb_logo-img-small.png";
        url = urlAPI + params.url;
    }
    
    const postData = {
        image : image, 
        url : url,
        description : params.description,
        title : params.title
    }

    try {
        let response = await fetch(urlEndpoint, {
            method: 'POST',
            body: JSON.stringify(postData),
        })

        let responseJson = await response.json();
        if(responseJson !== null) {
            return responseJson.url;
        }
    } catch (error) {
        console.log('SbC fetch error short url: ', error.message);
    }
}

export async function getOnyxTranslation(list, code) {
    const urlEndpoint = '/translation/getOnyxTranslation.php';
  
    const postData = {
        onyx_list: list,
        stock_code: code
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getOnixTranslationFile() {
    const urlEndpoint = '/translation/getOnyxTranslationFile.php';
  
    return apifetch.sbFetch(urlEndpoint, 'GET');
}
