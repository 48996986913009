import * as apifetch from './fetch.js';

export async function getConnCategoriesGenres() {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/catalog/getConnCategoriesGenres.php';

    try {
        let apiStr = urlEndpoint;
        let response = await fetch(apiStr, {
            method: 'GET',
            headers: new Headers({
    
            }),
        });
        let responseJson = await response.json();
        if(responseJson !== null) {
            let arrData = [];
            arrData = responseJson;

            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error genres: ', error.message);
    }
}

export async function getSbPublications(collection) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/catalog/getSbPublications.php';

    const postData = {
        collection: collection
    }

    try {
        let apiStr = urlEndpoint;
        let response = await fetch(apiStr, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
    
            }),
        });
        let responseJson = await response.json();
        if(responseJson !== null) {
            let arrData = [];
            arrData = responseJson;

            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error sb publications: ', error.message);
    }
}

export async function getPreview(media, isbn) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/catalog/getPreview.php';

    const postData = {
        media: media,
        isbn: isbn
    }

    try {
        let apiStr = urlEndpoint;
        let response = await fetch(apiStr, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
    
            }),
        });
        let responseJson = await response.json();
        if(responseJson !== null) {
            let arrData = [];
            arrData = responseJson;

            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error preview: ', error.message);
    }
}

export async function getPreviewVideo(isbn) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/catalog/getPreviewVideo.php';

    const postData = {
        isbn: isbn
    }

    try {
        let apiStr = urlEndpoint;
        let response = await fetch(apiStr, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
    
            }),
        });
        let responseJson = await response.json();
        if(responseJson !== null) {
            let arrData = [];
            arrData = responseJson;

            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error preview: ', error.message);
    }
}

export async function getReviews(sku) {
    const urlEndpoint = '/content/reviews/' + sku;

    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function getReviewer(id) {
    const urlEndpoint = '/content/reviewer/' + id;

    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function XgetReviewer(id) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/catalog/getReviewer.php';

    const postData = {
        id: id
    }

    try {
        let apiStr = urlEndpoint;
        let response = await fetch(apiStr, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
    
            }),
        });
        let responseJson = await response.json();
        if(responseJson !== null) {
            let arrData = [];
            arrData = responseJson;

            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error reviewer: ', error.message);
    }
}

export async function translateNURtoValue(nur) {
    const urlEndpoint = '/catalog/translateNURtoValue.php';

    const postData = {
        nur: nur
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function checkIfNURCategory(category) {
    const urlEndpoint = '/catalog/checkIfNURCategory.php';

    const postData = {
        category: category
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}



export async function addReview(form, book_data, form_data, upload_file) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/catalog/addReview.php';

    const postData = {
        book: book_data,
        data: form_data
    }

    try {
        let response = await fetch(urlEndpoint, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
                
            }),
        });
        let responseJson = await response.json();
        if(responseJson !== null) {
            let arrData = [];
            console.log('SbC:: return file upload::', responseJson)
            arrData = responseJson;
            return arrData;
        }
    } catch (error) {
        let arrData = {'data': [{error : 'An error has occurred'}]};
        return arrData;
    }
}

export async function getNotification($sku) {
    const urlEndpoint = '/v2/catalog/notifications/' + $sku;

    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function getNotifications() {
    const urlEndpoint = '/v2/catalog/notifications';

    return apifetch.authFetch(urlEndpoint, 'GET');
}




